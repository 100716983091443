import {
    APP_URL,
    AUTH_TOKEN_COOKIE,
    COUNTRY_SS_KEY,
    CURRENCY_SS_KEY,
    NEXT_APP_SETTINGS_PAYMENT_MODAL_URL,
    NEXT_APP_SETTINGS_PLANS_YEARLY_URL,
    PASSWORD_MAX_COMPLEXITY_LEVEL,
    REFRESH_TOKEN_COOKIE,
    SHARED_FUNNEL_SAVE_SLUG,
    SUBSCRIPTION_ERROR_CODES,
    SUBSCRIPTION_STATUS,
    WELCOME_PAGE_SLUG,
} from './constants';
import {
    AGENCY_PLAN_IDS,
    BUNDLE_PLAN_IDS,
    CRASHCOURSE_BUSINESS_PLAN_IDS,
    INDIVIDUAL_PLAN_IDS,
    JOURNEYS_BUNDLES_PLAN_IDS,
    JOURNEYS_BUSINESS_PLAN_IDS,
    REGULAR_PLAN_IDS,
    STARTER_BUNDLE_BUSINESS_PLAN_IDS,
    STARTER_BUNDLE_PLAN_IDS,
    WEBINAR_BUNDLE_BUSINESS_PLAN_IDS,
    WEBINAR_BUNDLE_PLAN_IDS,
} from '@app/marketing/constants';
import {
    JOURNEYS_BUNDLE_PLANS,
    STARTER_BUNDLE_PLANS,
    WEBINAR_BUNDLE_PLANS,
} from '@app/pricing/constants';

// Utils
import zxcvbn from 'zxcvbn';
import qs from 'query-string';
import Cookies from 'js-cookie';
import { i18n } from 'next-i18next';
import { setAuthTokenForApi } from '@core/api';

// Types
import type { AxiosResponse } from 'axios';
import type { PaymentMethod } from '@stripe/stripe-js';
import type { Address, ChargebeeAddon, PayPalCheckoutDetails } from './types';
import { Currency } from './types';

// set cookies and API tokens from signup response
export const setTokensFromResponse = (response: AxiosResponse, rememberMe?: Boolean) => {
    // Extract token from signup response
    const token = response?.data?.data?.attributes?.token;
    const refreshToken = response?.data?.data?.attributes?.refreshToken;

    const cookiesOptions =
        process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? { domain: '.perspective.co' } : {};

    // Set Cookies and token for API
    Cookies.set(AUTH_TOKEN_COOKIE, `Bearer ${token}`, cookiesOptions);

    if (rememberMe) {
        Cookies.set(REFRESH_TOKEN_COOKIE, refreshToken, { ...cookiesOptions, expires: 30 });
    }

    setAuthTokenForApi(Cookies.get(AUTH_TOKEN_COOKIE));
};

export const getPlanIdWithoutTimeframe = (planId: string) => {
    if (!planId) return;

    const splitPlanId = planId?.split('-');

    if (splitPlanId.includes(Currency.GBP) || splitPlanId.includes(Currency.USD)) {
        return splitPlanId.slice(0, 3).join('-');
    }
    return splitPlanId.slice(0, 2).join('-');
};

export const splitName = (name: string) => {
    const trimmed = name.trim();
    return trimmed.indexOf(' ') > 0
        ? [trimmed.substring(0, trimmed.indexOf(' ')), trimmed.substring(trimmed.indexOf(' ') + 1)]
        : [trimmed, ''];
};

export const isUserAuthorized = () => !!Cookies.get(AUTH_TOKEN_COOKIE);

export const handleChargebeeError = (
    errorMessage: string,
    isUpdateSubscription: boolean = false,
) => {
    if (!errorMessage) return SUBSCRIPTION_ERROR_CODES.SUBSCRIPTION_ERROR;

    return errorMessage.toLowerCase().includes('insufficient funds')
        ? SUBSCRIPTION_ERROR_CODES.INSUFFICIENT_FUNDS
        : isUpdateSubscription
          ? SUBSCRIPTION_ERROR_CODES.UPDATE_SUBSCRIPTION_ERROR
          : SUBSCRIPTION_ERROR_CODES.SUBSCRIPTION_ERROR;
};

export const handleStripeError = (
    error: string,
    defaultValue: string = SUBSCRIPTION_ERROR_CODES.GENERIC_DECLINE,
) => (error === SUBSCRIPTION_ERROR_CODES.INSUFFICIENT_FUNDS ? error : defaultValue);

const STRIPE_ERRORS = [
    SUBSCRIPTION_ERROR_CODES.GENERIC_DECLINE,
    SUBSCRIPTION_ERROR_CODES.INSUFFICIENT_FUNDS,
    SUBSCRIPTION_ERROR_CODES.STRIPE_AUTH_FAILED,
];

export const isStripeError = (error: string) => STRIPE_ERRORS.includes(error);

export const buildUrl = (url: string, obj: { [key: string]: string }) => {
    let urlWithParams = url;

    Object.keys(obj).forEach((key) => {
        const value = obj[key];

        if (urlWithParams.indexOf(`:${key}`) > -1) {
            urlWithParams = urlWithParams.replace(`:${key}`, value);
        } else if (urlWithParams.indexOf(`:${key}?`) > -1) {
            urlWithParams = urlWithParams.replace(`:${key}?`, value);
        }
    });

    return urlWithParams;
};

export const getPostLoginUrl = ({
    successRedirectUrl,
    subscriptionId,
    customerAllowedToCreateSubscription,
    welcomeUrl,
    isBundle,
    query,
}: {
    successRedirectUrl?: string;
    subscriptionId?: string;
    customerAllowedToCreateSubscription?: boolean;
    welcomeUrl?: string;
    isBundle?: boolean;
    query: {
        planId?: string;
        coupon?: string;
        partnerKey?: string;
        campaignId?: string;
    };
}) => {
    if (customerAllowedToCreateSubscription && !subscriptionId) {
        if (successRedirectUrl) {
            const finalQuery = qs.stringify({
                ...query,
                subscriptionId,
            });

            return successRedirectUrl + `?${finalQuery}`;
        }

        if (query.planId) return `/checkout?${qs.stringify(query)}`;

        return !query.coupon
            ? '/pricing'
            : `/pricing?${qs.stringify({ coupon: query.coupon, partnerKey: query.partnerKey })}`;
    } else if (isBundle && successRedirectUrl) {
        // Show upsell in Bundle Checkout
        const finalQuery = qs.stringify({
            ...query,
            subscriptionId,
            canCreateSubscription: customerAllowedToCreateSubscription,
        });

        return successRedirectUrl + `?${finalQuery}`;
    }

    if (subscriptionId && welcomeUrl) return welcomeUrl;

    if (subscriptionId) {
        const localizedAppUrl = new URL(getLocalizedAppUrl());

        const editorRedirectPathnameWithoutLeadingSlash = (
            !window?.location
                ? ''
                : ((qs.parse(window.location.search)?.redirect_pathname as string) ?? '')
        ).replace(/^\/+/, ''); // Remove leading slashes

        if (`/${editorRedirectPathnameWithoutLeadingSlash}`.startsWith(localizedAppUrl.pathname)) {
            // redirect_pathname is already localized: we >>overwrite<< the pathname
            localizedAppUrl.pathname = `/${editorRedirectPathnameWithoutLeadingSlash}`;
        } else {
            // Not localized yet: we >>append<< the pathname/resource
            localizedAppUrl.pathname += `/${editorRedirectPathnameWithoutLeadingSlash}`;
        }

        return localizedAppUrl.href;
    }
};

export const getPostSignupUrl = (
    redirectUrl: string,
    query: {
        planId?: string;
        coupon?: string;
        partnerKey?: string;
        campaignId?: string;
    },
) => {
    if (!query.planId)
        return !query.coupon && !query.partnerKey
            ? '/pricing'
            : `/pricing?${qs.stringify({ coupon: query.coupon, partnerKey: query.partnerKey })}`;

    if (redirectUrl) {
        const finalQuery = qs.stringify(query);

        return redirectUrl + `?${finalQuery}`;
    }

    return `/checkout?${qs.stringify(query)}`;
};

export const transformStripeBillingDetails = (
    billingDetails: PaymentMethod.BillingDetails,
): Address => ({
    line1: billingDetails.address?.line1,
    line2: billingDetails.address?.line2,
    city: billingDetails.address?.city,
    country: billingDetails.address?.country?.toUpperCase(),
    state: billingDetails.address?.state,
    zip: billingDetails.address?.postal_code,
    phone: billingDetails.phone,
});

export const transformPaypalCheckoutDetails = (
    checkoutDetails: PayPalCheckoutDetails,
): Address => ({
    line1: checkoutDetails.SHIPTOSTREET,
    city: checkoutDetails.SHIPTOCITY,
    country: checkoutDetails.COUNTRYCODE?.toUpperCase(),
    state: checkoutDetails.SHIPTOSTATE,
    zip: checkoutDetails.SHIPTOZIP,
});

export const estimatePasswordComplexity = (password: string) => {
    if (!password) return 0;

    if (password.length >= 100) return PASSWORD_MAX_COMPLEXITY_LEVEL;

    const passwordData = zxcvbn(password);

    return passwordData?.score;
};

export const getLocalizedAppUrl = () => {
    const locale = i18n?.resolvedLanguage;

    return APP_URL + `/${locale}`;
};

export const getWelcomePageUrl = () => getLocalizedAppUrl() + WELCOME_PAGE_SLUG;

export const getSharedFunnelSaveUrl = () => getLocalizedAppUrl() + SHARED_FUNNEL_SAVE_SLUG;

export const getNextAppSettingsPlansUrl = () =>
    getLocalizedAppUrl() + NEXT_APP_SETTINGS_PLANS_YEARLY_URL;

export const getNextAppSettingsPaymentModalUrl = () =>
    getLocalizedAppUrl() + NEXT_APP_SETTINGS_PAYMENT_MODAL_URL;

export const getIsAllowedToCreateSubscription = ({
    addons,
    subscriptionPlanId,
    subscriptionStatus,
    subscriptionCreatedAt,
    bundleId,
    successRedirectUrl,
}: {
    addons?: ChargebeeAddon[];
    subscriptionPlanId?: string;
    subscriptionStatus?: string;
    subscriptionCreatedAt?: number;
    bundleId?: string; // this is planId
    successRedirectUrl?: string; // Used to understand which bundle is the user buying
}) => {
    if (!subscriptionPlanId) {
        return true;
    }

    const isBundleLogin = BUNDLE_PLAN_IDS.includes(bundleId);
    const isJourneysLogin =
        JOURNEYS_BUNDLES_PLAN_IDS.includes(bundleId) &&
        [JOURNEYS_BUNDLE_PLANS.business.slug, JOURNEYS_BUNDLE_PLANS.advanced.slug].some((slug) =>
            successRedirectUrl?.includes(slug),
        );
    const isStarterBundleLogin =
        STARTER_BUNDLE_PLAN_IDS.includes(bundleId) &&
        [STARTER_BUNDLE_PLANS.business.slug, STARTER_BUNDLE_PLANS.advanced.slug].some((slug) =>
            successRedirectUrl?.includes(slug),
        );
    const isWebinarBundleLogin =
        WEBINAR_BUNDLE_PLAN_IDS.includes(bundleId) &&
        [WEBINAR_BUNDLE_PLANS.business.slug, WEBINAR_BUNDLE_PLANS.advanced.slug].some((slug) =>
            successRedirectUrl?.includes(slug),
        );

    if (isBundleLogin) {
        return getIsAllowedToPurchaseBundlePlan(subscriptionPlanId, subscriptionStatus);
    }

    if (isJourneysLogin) {
        return getIsAllowedToCreateJourneysBundle(subscriptionPlanId, bundleId, addons);
    }

    if (isStarterBundleLogin) {
        return getIsAllowedToCreateStarterBundle(
            subscriptionPlanId,
            subscriptionCreatedAt,
            subscriptionStatus,
            bundleId,
            addons,
        );
    }

    if (isWebinarBundleLogin) {
        return getIsAllowedToCreateWebinarBundle(
            subscriptionPlanId,
            subscriptionCreatedAt,
            subscriptionStatus,
            bundleId,
            addons,
        );
    }

    return true;
};

export const getIsAllowedToPurchaseBundlePlan = (
    subscriptionPlanId?: string,
    subscriptionStatus?: string,
) => {
    // Only regular subscriptions in trial can purchase a Crashcourse Bundle
    return (
        REGULAR_PLAN_IDS.includes(subscriptionPlanId) &&
        subscriptionStatus === SUBSCRIPTION_STATUS.InTrial
    );
};

export const getIsAllowedToCreateJourneysBundle = (
    subscriptionPlanId?: string,
    bundleId?: string,
    addons?: ChargebeeAddon[],
) => {
    const hasAgencyPlan = AGENCY_PLAN_IDS.includes(subscriptionPlanId);
    const hasBundlePlan = BUNDLE_PLAN_IDS.includes(subscriptionPlanId);
    const hasBusinessCrashcourseBundlePlan =
        CRASHCOURSE_BUSINESS_PLAN_IDS.includes(subscriptionPlanId);
    const hasIndividualPlan = INDIVIDUAL_PLAN_IDS.includes(subscriptionPlanId);
    const preventBusinessPlan =
        hasAgencyPlan || hasIndividualPlan || (hasBundlePlan && !hasBusinessCrashcourseBundlePlan);

    // If user has a subscription, check for plan and prevent downgrade
    if (preventBusinessPlan && JOURNEYS_BUSINESS_PLAN_IDS.includes(bundleId)) {
        return false;
    }

    // Prevent Individual Plan with prepaid addons
    if (hasIndividualPlan && getHasContingentAddons(addons)) {
        return false;
    }

    return true;
};

export const getIsAllowedToCreateStarterBundle = (
    subscriptionPlanId?: string,
    subscriptionCreatedAt?: number, // in seconds
    subscriptionStatus?: string,
    bundleId?: string,
    addons?: ChargebeeAddon[],
) => {
    const hasAgencyPlan = AGENCY_PLAN_IDS.includes(subscriptionPlanId);
    const hasBundlePlan = BUNDLE_PLAN_IDS.includes(subscriptionPlanId);
    const hasBusinessCrashcourseBundlePlan =
        CRASHCOURSE_BUSINESS_PLAN_IDS.includes(subscriptionPlanId);
    const hasIndividualPlan = INDIVIDUAL_PLAN_IDS.includes(subscriptionPlanId);
    const preventBusinessPlan =
        hasAgencyPlan || hasIndividualPlan || (hasBundlePlan && !hasBusinessCrashcourseBundlePlan);
    const THREE_MONTHS_IN_MS = 90 * 24 * 60 * 60 * 1000;
    const isCustomerOlderThan3Months =
        subscriptionCreatedAt && Date.now() - subscriptionCreatedAt * 1000 > THREE_MONTHS_IN_MS;

    // If user is in trial, allow Starter Bundle
    if (subscriptionStatus === 'in_trial') {
        return true;
    }

    // If user has a subscription, check for plan and prevent downgrade
    if (preventBusinessPlan && STARTER_BUNDLE_BUSINESS_PLAN_IDS.includes(bundleId)) {
        return false;
    }

    // Prevent Individual Plan with prepaid addons
    if (hasIndividualPlan && getHasContingentAddons(addons)) {
        return false;
    }

    // If customer is older than 3 months, prevent Starter Bundle
    if (isCustomerOlderThan3Months) {
        return false;
    }

    return true;
};

export const getIsAllowedToCreateWebinarBundle = (
    subscriptionPlanId?: string,
    subscriptionCreatedAt?: number,
    subscriptionStatus?: string,
    bundleId?: string,
    addons?: ChargebeeAddon[],
) => {
    const hasAgencyPlan = AGENCY_PLAN_IDS.includes(subscriptionPlanId);
    const hasBundlePlan = BUNDLE_PLAN_IDS.includes(subscriptionPlanId);
    const hasBusinessCrashcourseBundlePlan =
        CRASHCOURSE_BUSINESS_PLAN_IDS.includes(subscriptionPlanId);
    const hasIndividualPlan = INDIVIDUAL_PLAN_IDS.includes(subscriptionPlanId);
    const preventBusinessPlan =
        hasAgencyPlan || hasIndividualPlan || (hasBundlePlan && !hasBusinessCrashcourseBundlePlan);
    const THREE_MONTHS_IN_MS = 90 * 24 * 60 * 60 * 1000;
    const isCustomerOlderThan3Months =
        subscriptionCreatedAt && Date.now() - subscriptionCreatedAt * 1000 > THREE_MONTHS_IN_MS;

    // If user is in trial, allow Webinar Bundle
    if (subscriptionStatus === 'in_trial') {
        return true;
    }

    // If user has a subscription, check for plan and prevent downgrade
    if (preventBusinessPlan && WEBINAR_BUNDLE_BUSINESS_PLAN_IDS.includes(bundleId)) {
        return false;
    }

    // Prevent Individual Plan with prepaid addons
    if (hasIndividualPlan && getHasContingentAddons(addons)) {
        return false;
    }

    // If customer is older than 3 months, prevent Webinar Bundle
    if (isCustomerOlderThan3Months) {
        return false;
    }

    return true;
};

const getHasContingentAddons = (addons: ChargebeeAddon[]) => {
    return addons?.some(
        (addon) =>
            (addon.id.startsWith('ps-prepaid') &&
                !addon.id.startsWith('ps-prepaid-workspaces') &&
                addon.quantity > 0) ||
            (addon.id.startsWith('ps-prepaid-workspaces') && addon.quantity > 1), // Prepaid workspaces is not a regular contingent addon
    );
};

export const getCurrencyFromSS = (): Currency => {
    return sessionStorage.getItem(CURRENCY_SS_KEY) as Currency;
};

export const getCountryFromSS = (): string => {
    return sessionStorage.getItem(COUNTRY_SS_KEY);
};

export const currencySymbolMap: Record<Currency, '€' | '$' | '£'> = {
    [Currency.EUR]: '€',
    [Currency.USD]: '$',
    [Currency.GBP]: '£',
};

export const getIsUSCustomer = (country: string) => {
    return country?.toUpperCase() === 'US';
};

export const getIsUSDCustomer = (currency: Currency) => {
    return currency === Currency.USD;
};

export const getIsDACHCustomer = (country: string) => {
    return (
        country?.toUpperCase() === 'DE' ||
        country?.toUpperCase() === 'AT' ||
        country?.toUpperCase() === 'CH'
    );
};
